
function checkValue(input) {
  if (input.value.trim()) {
    input.parentNode.classList.add('is-filled');
  } else {
    input.parentNode.classList.remove('is-filled');
  }
}

function floatingLabels(field) {
  const input = field.querySelector('.form-item__textfield, .form-item__select');

  if (!input) {
    return;
  }

  field.classList.toggle('is-filled', input.value.trim());

  input.addEventListener('change', function(e) {
    field.classList.toggle('is-filled', input.value.trim());
  });

  input.addEventListener('focus', function() {
    field.classList.add('has-focus');
  });

  input.addEventListener('blur', function() {
    field.classList.remove('has-focus');
  });
}

function togglePasswordVisibility(field) {
  const passwordToggle = field.querySelector('.form-item__pwd-toggle');
  const input = field.querySelector('.form-item__textfield[type="password"]');

  function handleToggleClick(e) {
    const isShowing = passwordToggle.classList.contains('is-showing-password');
    passwordToggle.classList.toggle('is-showing-password', !isShowing);
    input.setAttribute('type', isShowing ? 'password' : 'text');
  }

  passwordToggle.addEventListener('click', handleToggleClick);
}

function textareaCounter(textarea) {
  const counter = textarea.parentNode.querySelector('.form-item__textarea-counter');
  const maxLength = textarea.getAttribute('maxlength');

  function updateCount() {
    const charCount = textarea.value.length;
    counter.innerText = `${charCount} / ${maxLength}`;
  }

  updateCount();

  textarea.addEventListener('input', function(e) {
    updateCount();
  });
}

(function() {
  // Enable floating labels
  const textfields = [].slice.call(document.querySelectorAll('.form-item'));
  textfields.forEach(floatingLabels);

  // Enable password toggle
  const passwordInputs = [].slice.call(document.querySelectorAll('.form-item__textfield[type="password"]'));
  const passwordFields = passwordInputs
    .map(function(input) {
      return input.parentNode;
    })
    .filter(function(field) {
      return field.classList.contains('form-item');
    });
  passwordFields.forEach(togglePasswordVisibility);

  // Textarea counter
  const textareas = [].slice.call(document.querySelectorAll('.form-item__textarea'));
  textareas.forEach(textareaCounter);

  // Date input mask
  const dateField = document.querySelector('.form-item__textfield--date');

  if (dateField) {
    const dateMask = new Cleave(dateField, {
      date: true,
      delimiter: ' / ',
      datePattern: ['Y', 'm', 'd'],
    });
  }
})();
